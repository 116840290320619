<template>
  <div>
    <el-tabs @tab-click="handleTabClick">
      <el-tab-pane v-if="typeAuth" :label="$t('maintWorkOrder.maintType')">
        <vm-table ref="vmTable" v-loading="loading" :filter.sync="typeSearch" url="maintType">
          <template slot="adSearch">
            <vm-search :label="$t('common.filter')">
              <el-input v-model.trim="typeSearch.filter" :placeholder="$t('common.search')" clearable></el-input>
            </vm-search>
            <vm-search :label="$t('maintWorkOrder.productTypeName')">
              <el-select v-model="typeSearch.elevatorProductType" :placeholder="$t('common.pleaseSelect')" clearable>
                <el-option v-for="item in productTypeList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </vm-search>
          </template>
          <template slot="toolbar">
            <el-button class="addBtn" @click="handleAddType">
              {{ $t("common.add") }}
            </el-button>
          </template>
          <el-table-column prop="maintTypeName" :label="$t('maintWorkOrder.maintTypeName')" width="200" align="center">
          </el-table-column>
          <el-table-column prop="elevatorProductType" :label="$t('maintWorkOrder.productTypeName')">
            <template v-slot="scope">
              <span v-if="scope.row.elevatorProductType === '曳引驱动乘客电梯'">
                {{ $t("maintWorkOrder.elevatorType1") }}
              </span>
              <span v-if="scope.row.elevatorProductType === '曳引驱动载货电梯'">
                {{ $t("maintWorkOrder.elevatorType2") }}
              </span>
              <span v-if="scope.row.elevatorProductType === '强制驱动载货电梯'">
                {{ $t("maintWorkOrder.elevatorType3") }}
              </span>
              <span v-if="scope.row.elevatorProductType === '液压乘客电梯'">
                {{ $t("maintWorkOrder.elevatorType4") }}
              </span>
              <span v-if="scope.row.elevatorProductType === '液压载货电梯'">
                {{ $t("maintWorkOrder.elevatorType5") }}
              </span>
              <span v-if="scope.row.elevatorProductType === '自动扶梯'">
                {{ $t("maintWorkOrder.elevatorType6") }}
              </span>
              <span v-if="scope.row.elevatorProductType === '自动人行道'">
                {{ $t("maintWorkOrder.elevatorType7") }}
              </span>
              <span v-if="scope.row.elevatorProductType === '防爆电梯'">
                {{ $t("maintWorkOrder.elevatorType8") }}
              </span>
              <span v-if="scope.row.elevatorProductType === '消防员电梯'">
                {{ $t("maintWorkOrder.elevatorType9") }}
              </span>
              <span v-if="scope.row.elevatorProductType === '杂物电梯'">
                {{ $t("maintWorkOrder.elevatorType10") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="maintTypeClassify" :label="$t('maintWorkOrder.maintType')" align="center" width="180">
            <template v-slot="scope">
              <span v-if="scope.row.maintTypeClassify === '半月保'">
                {{ $t("maintWorkOrder.type.halfMonth") }}
              </span>
              <span v-else-if="scope.row.maintTypeClassify === '季保'">
                {{ $t("maintWorkOrder.type.month") }}
              </span>
              <span v-else-if="scope.row.maintTypeClassify === '半年保'">
                {{ $t("maintWorkOrder.type.halfYear") }}
              </span>
              <span v-else-if="scope.row.maintTypeClassify === '年保'">
                {{ $t("maintWorkOrder.type.year") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" :label="$t('common.remark')" width="200"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="isDefault" :label="$t('common.isDefault')" align="center" width="130">
            <template v-slot="scope">
              <el-tag v-if="scope.row.isDefault === 1" type="success">
                {{ $t("common.yes") }}
              </el-tag>
              <el-tag v-else-if="scope.row.isDefault === 0" type="info">
                {{ $t("common.no") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.operate')" align="center" width="120">
            <template v-slot="scope">
              <el-button type="text" :disabled="scope.row.isDefault === 1 && currentUserType !== 0"
                @click="handleEditType(scope.row)">
                {{ $t("common.edit") }}
              </el-button>
              <el-button type="text" class="operateDelBtn"
                :disabled="scope.row.isDefault === 1 && currentUserType !== 0" @click="handleDeleteType(scope.row)">
                {{ $t("common.delete") }}
              </el-button>
            </template>
          </el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane v-if="itemAuth" :label="$t('maintWorkOrder.maintItem')">
        <vm-table ref="itemTable" v-loading="itemLoading" :filter.sync="itemSearch" url="maintItem">
          <template slot="adSearch">
            <vm-search :label="$t('maintWorkOrder.maintenanceProjectName')">
              <el-input v-model.trim="itemSearch.itemName" :placeholder="$t('common.search')" clearable></el-input>
            </vm-search>
            <vm-search :label="$t('maintWorkOrder.maintArea')">
              <el-select v-model="itemSearch.maintArea" :placeholder="$t('common.pleaseSelect')" clearable>
                <el-option v-for="item in maintAreaOption" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </vm-search>
          </template>
          <template slot="toolbar">
            <el-button class="addBtn" @click="handleAddItem">
              {{ $t("common.add") }}
            </el-button>
          </template>
          <el-table-column prop="itemName" show-overflow-tooltip
            :label="$t('maintWorkOrder.maintenanceProjectName')"></el-table-column>
          <el-table-column prop="maintContent" show-overflow-tooltip
            :label="$t('maintWorkOrder.maintContent')"></el-table-column>
          <el-table-column prop="maintRequire" show-overflow-tooltip
            :label="$t('maintWorkOrder.maintRequire')"></el-table-column>
          <el-table-column prop="maintArea" :label="$t('maintWorkOrder.maintArea')" width="150" align="center">
            <template v-slot="scope">
              <span v-if="scope.row.maintArea === '机房'">
                {{ $t("maintWorkOrder.maintenanceArea.machineRoom") }}
              </span>
              <span v-else-if="scope.row.maintArea === '轿厢'">
                {{ $t("maintWorkOrder.maintenanceArea.bridge") }}
              </span>
              <span v-else-if="scope.row.maintArea === '层站'">
                {{ $t("maintWorkOrder.maintenanceArea.layer") }}
              </span>
              <span v-else-if="scope.row.maintArea === '井道及底坑'">
                {{ $t("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit") }}
              </span>
              <span v-else-if="scope.row.maintArea === '扶梯'">
                {{ $t("maintWorkOrder.maintenanceArea.escalator") }}
              </span>
              <span v-else-if="scope.row.maintArea === '其他'">
                {{ $t("maintWorkOrder.maintenanceArea.other") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" :label="$t('common.remark')" width="130"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="isDefault" :label="$t('common.isDefault')" align="center" width="130">
            <template v-slot="scope">
              <el-tag v-if="scope.row.isDefault === 1" type="success">
                {{ $t("common.yes") }}
              </el-tag>
              <el-tag v-else-if="scope.row.isDefault === 0" type="info">
                {{ $t("common.no") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.operate')" align="center" width="100">
            <template v-slot="scope">
              <el-button type="text" :disabled="scope.row.isDefault === 1 && currentUserType !== 0"
                @click="handleEditItem(scope.row)">
                {{ $t("common.edit") }}
              </el-button>
              <el-button type="text" class="operateDelBtn"
                :disabled="scope.row.isDefault === 1 && currentUserType !== 0" @click="handleDeleteItem(scope.row)">
                {{ $t("common.delete") }}
              </el-button>
            </template>
          </el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane v-if="settingAuth" :label="$t('maintWorkOrder.maintSetting')">
        <maint-setting></maint-setting>
      </el-tab-pane>
      <el-tab-pane :label="$t('maintWorkOrder.branchAgency')">
        <branch-agency-list></branch-agency-list>
      </el-tab-pane>
      <el-tab-pane v-if="$i18n.isCn && showOrgId" :label="$t('maintWorkOrder.safetySpotCheck')">
        <safe-check-setting-list></safe-check-setting-list>
      </el-tab-pane>
    </el-tabs>
    <type-edit-dialog ref="typeEditPage" @save-success="getList(-1)"></type-edit-dialog>
    <item-edit-dialog ref="itemEditPage" @save-success="getItemList(-1)"></item-edit-dialog>
  </div>
</template>
<script>
import TypeEditDialog from "./maintTypeEdit.vue";
import ItemEditDialog from "./maintItemEdit.vue";
import auth from "@/util/auth";
import MaintSetting from "@/views/newMaintWorkOrder/maintSetting/maintSettingList";
import BranchAgencyList from "@/views/newMaintWorkOrder/maintSetting/branchAgencyList";
import SafeCheckSettingList from "@/views/newMaintWorkOrder/maintSetting/SafeCheckSettingList";

export default {
  components: { TypeEditDialog, ItemEditDialog, MaintSetting, BranchAgencyList, SafeCheckSettingList },
  data() {
    return {
      settingAuth: this.$auth(326),
      typeAuth: this.$auth(327),
      itemAuth: this.$auth(328),
      showOrgId: auth.getUsername() === "superAdmin" || auth.getUsername() === "admin",
      loading: true,
      itemLoading: true,
      currentUserType: auth.getUserType(),
      productTypeList: [],
      maintAreaOption: [
        { value: "机房", label: this.$t("maintWorkOrder.maintenanceArea.machineRoom") },
        { value: "轿厢", label: this.$t("maintWorkOrder.maintenanceArea.bridge") },
        { value: "层站", label: this.$t("maintWorkOrder.maintenanceArea.layer") },
        { value: "井道及底坑", label: this.$t("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit") },
        { value: "扶梯", label: this.$t("maintWorkOrder.maintenanceArea.escalator") },
        { value: "其他", label: this.$t("maintWorkOrder.maintenanceArea.other") },
      ],
      typeSearch: {
        filter: "",
        elevatorProductType: "",
      },
      itemSearch: {
        itemName: "",
        maintArea: "",
      },
    };
  },
  mounted() {
    this.getList(1);
    this.getElevatorProductTypeList();
  },
  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
      this.loading = false;
    },
    getItemList(pageNum) {
      this.$refs.itemTable.getList(pageNum);
      this.itemLoading = false;
    },
    getElevatorProductTypeList() {
      this.$api.getList("elevatorProductTypes").then(response => {
        this.productTypeList = [];
        for (let productType of response.data) {
          let item = "";
          if (this.$i18n.isCn) {
            item = { value: productType.elevatorProductTypeName, label: productType.elevatorProductTypeName };
          } else {
            item = { value: productType.elevatorProductTypeName, label: productType.elevatorProductTypeNameEn };
          }
          this.productTypeList.push(item);
        }
      }).catch((error) => {
        if (error.response) {
          this.$message.error(this.$t("maintWorkOrder.tip.getElevatorProductTypeError") + "," + error.response.data.message);
        }
      });
    },
    handleTabClick(tab) {
      if (tab.index === "1") {
        this.getItemList(1);
      }
    },
    handleAddType() {
      this.$refs.typeEditPage.open(0);
    },
    handleEditType(row) {
      this.$refs.typeEditPage.open(row.id);
    },
    handleDeleteType(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("maintWorkOrder.maintType") + " " + row.maintTypeName + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById("maintType", row.id).then(() => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
    handleAddItem() {
      this.$refs.itemEditPage.open(0);
    },
    handleEditItem(row) {
      this.$refs.itemEditPage.open(row.id);
    },
    handleDeleteItem(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("maintWorkOrder.maintItem") + " " + row.itemName + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById("maintItem", row.id).then(() => {
          this.getItemList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
