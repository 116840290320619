<template>
  <div v-loading="loading">
    <el-form ref="formValidate" :model="maintSetting" :rules="ruleValidate">
      <el-form-item prop="autoSendDay" :label="$t('maintWorkOrder.setting.autoSendDay')">
        <el-input v-model.number="maintSetting.autoSendDay" :placeholder="'0-7'" style="width: 67px">
          <template slot="suffix">{{ $t("maintWorkOrder.setting.day") }}</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="maintDistance" :label="$t('maintWorkOrder.setting.maintDistance')">
        <el-input v-model.number="maintSetting.maintDistance" :placeholder="'> 0'"
          :style="{ width: $l('100px', '80px') }">
          <template slot="suffix">{{ $t("maintWorkOrder.setting.meter") }}</template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('maintWorkOrder.setting.isAutoGenerate')">
        <el-switch v-model="maintSetting.isAutoGenerate" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item :label="$t('maintWorkOrder.setting.generateInitialTime')">
        <el-radio-group v-model="maintSetting.generateInitialTime">
          <el-radio :label="1"> {{ $t('maintWorkOrder.setting.completeDate') }}</el-radio>
          <el-radio :label="2"> {{ $t('maintWorkOrder.setting.planDate') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="maintCycleDay" :label="$t('maintWorkOrder.setting.maintCycleDay')">
        <el-input v-model.number="maintSetting.maintCycleDay" :placeholder="'1-90'" style="width: 75px">
          <template slot="suffix">{{ $t("maintWorkOrder.setting.day") }}</template>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('maintWorkOrder.setting.signInMethod')">
        <el-radio-group v-model="maintSetting.signInMethod">
          <el-radio :label="1"> {{ $t('maintWorkOrder.setting.photo') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('maintWorkOrder.setting.isSignInApp')">
        <el-switch v-model="maintSetting.isSignInApp" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item :label="$t('maintWorkOrder.setting.isOperationOverdue')">
        <el-switch v-model="maintSetting.isOperationOverdue" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item :label="$t('maintWorkOrder.setting.isTwoMaintainerCheckIn')">
        <el-switch v-model="maintSetting.isTwoMaintainerCheckIn" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item :label="$t('maintWorkOrder.setting.isPaperMaintPhotoRequired')">
        <el-switch v-model="maintSetting.isPaperMaintPhotoRequired" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item :label="$t('maintWorkOrder.setting.fieldWorkMethods')"></el-form-item>
      <el-form-item>
        <el-radio-group v-model="customDisable" @change="handleCustom">
          <div>
            <el-radio :label="true">
              {{ $t('maintWorkOrder.setting.default') }}</el-radio>
          </div>
          <div style="margin-top: 10px">
            <el-radio :label="false"> {{ $t('maintWorkOrder.setting.custom') }}</el-radio>
          </div>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-switch v-model="maintSetting.isPhotoCheckIn" :disabled="customDisable"
          :active-text="$t('maintWorkOrder.setting.isPhotoCheckIn')" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-switch v-model="maintSetting.isMaintainerMustSignInApp" :disabled="customDisable"
          :active-text="$t('maintWorkOrder.setting.isMaintainerMustSignInApp')" :active-value="1"
          :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item prop="isPhotoAbnormal" style="margin-left: 40px">
        <el-switch v-model="maintSetting.isPhotoAbnormal" :disabled="customDisable"
          :active-text="$t('maintWorkOrder.setting.isPhotoAbnormal')" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-switch v-model="maintSetting.isSoundRecordAbnormal" :disabled="customDisable"
          :active-text="$t('maintWorkOrder.setting.isSoundRecordAbnormal')" :active-value="1"
          :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-switch v-model="maintSetting.isContextAbnormal" :disabled="customDisable"
          :active-text="$t('maintWorkOrder.setting.isContextAbnormal')" :active-value="1"
          :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item prop="isPhotoNormal" style="margin-left: 40px">
        <el-switch v-model="maintSetting.isPhotoNormal" :disabled="customDisable"
          :active-text="$t('maintWorkOrder.setting.isPhotoNormal')" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-switch v-model="maintSetting.isSoundRecordNormal" :disabled="customDisable"
          :active-text="$t('maintWorkOrder.setting.isSoundRecordNormal')" :active-value="1"
          :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item style="margin-left: 40px">
        <el-switch v-model="maintSetting.isContextNormal" :disabled="customDisable"
          :active-text="$t('maintWorkOrder.setting.isContextNormal')" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
    </el-form>
    <el-button type="primary" :loading="submitLoading" @click="handleSubmit">
      {{ $t("common.save") }}
    </el-button>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    systemCode: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loading: true,
      submitLoading: false,
      customDisable: true,
      moduleName: "maintSetting",
      maintSetting: {
        id: null,
        autoSendDay: 3,
        maintDistance: 1000,
        isAutoGenerate: 0,
        generateInitialTime: 2,
        maintCycleDay: 15,
        signInMethod: 1,
        isSignInApp: 1,
        isOperationOverdue: 1,
        isTwoMaintainerCheckIn: 0,
        isPaperMaintPhotoRequired: 0,
        isPhotoCheckIn: 0,
        isMaintainerMustSignInApp: 0,
        isPhotoAbnormal: 0,
        isSoundRecordAbnormal: 0,
        isContextAbnormal: 0,
        isPhotoNormal: 0,
        isSoundRecordNormal: 0,
        isContextNormal: 0,
        orgId: "",
        creatorId: "",
      },
      ruleValidate: {
        autoSendDay: [
          {
            type: "number",
            min: 0,
            max: 7,
            message: this.$t("maintWorkOrder.tip.sendTip"),
            trigger: "blur",
          },
        ],
        maintDistance: [
          {
            type: "number",
            min: 0,
            message: this.$l("maintWorkOrder.tip.distanceTip"),
            trigger: "blur",
          },
        ],
        maintCycleDay: [
          {
            type: "number",
            min: 1,
            max: 90,
            message: this.$l("maintWorkOrder.tip.cycleTip"),
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.systemCode === "admin") {
        this.moduleName = "maintSetting/global";
      }
      this.$api.getList(this.moduleName).then(res => {
        if (res.data) {
          this.maintSetting = Object.assign(this.maintSetting, res.data);
        }
        this.loading = false;
        if (this.maintSetting.isPhotoCheckIn === 1 ||
          this.maintSetting.isMaintainerMustSignInApp === 1 ||
          this.maintSetting.isPhotoAbnormal === 1 ||
          this.maintSetting.isSoundRecordAbnormal === 1 ||
          this.maintSetting.isContextAbnormal === 1 ||
          this.maintSetting.isPhotoNormal === 1 ||
          this.maintSetting.isSoundRecordNormal === 1 ||
          this.maintSetting.isContextNormal === 1) {
          this.customDisable = false;
        }
      }).catch((error) => {
        this.loading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },
    handleCustom() {
      if (this.customDisable) {
        this.maintSetting.isPhotoCheckIn = 0;
        this.maintSetting.isMaintainerMustSignInApp = 0;
        this.maintSetting.isPhotoAbnormal = 0;
        this.maintSetting.isSoundRecordAbnormal = 0;
        this.maintSetting.isContextAbnormal = 0;
        this.maintSetting.isPhotoNormal = 0;
        this.maintSetting.isSoundRecordNormal = 0;
        this.maintSetting.isContextNormal = 0;
      }
    },
    handleSubmit() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          this.$http.post(this.moduleName, this.maintSetting).then(() => {
            this.submitLoading = false;
            this.$message.success(this.$t("common.tip.saveSuccess"));
            this.getData();
          }).catch(error => {
            this.submitLoading = false;
            this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>