<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" url="branchAgency">
      <el-button class="addBtn" slot="toolbar" @click="$refs.branchAgencyEdit.open()">
        {{ $t("common.add") }}
      </el-button>
      <el-table-column prop="branchName" :label="$t('maintWorkOrder.branchName')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="creator" :label="$t('common.creator')" align="center" width="100"></el-table-column>
      <el-table-column prop="createTime" :label="$t('common.createTime')" align="center" width="140"></el-table-column>
      <el-table-column :label="$t('common.operate')" align="center" width="100" fixed="right">
        <template v-slot="scope">
          <el-button type="text" @click="$refs.branchAgencyEdit.open(scope.row.id)">
            {{ $t("common.edit") }}
          </el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete")
            }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <branch-agency-edit ref="branchAgencyEdit" @save-success="getList(-1)"></branch-agency-edit>
  </div>
</template>

<script>
import BranchAgencyEdit from "@/views/newMaintWorkOrder/maintSetting/branchAgencyEdit";

export default {
  components: { BranchAgencyEdit },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleDelete(row) {
      console.log(row);
      this.$confirm(this.$t("common.delete") + " " + row.branchName + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), { type: "warning" }).then(() => {
        this.$http.delete("branchAgency", row.id).then(() => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        });
      });
    },
  },
};
</script>

<style scoped></style>