<template>
  <el-dialog v-el-drag-dialog :close-on-click-modal="false" :visible.sync="dialogVisible"
    :title="model.id ? $t('common.edit') : $t('common.add')" width="500px" @close="$reset('form')">
    <el-form ref="form" :label-width="$l('contract.labelWidth', '80px')" :model="model">
      <div>
        <el-form-item :label="$t('maintWorkOrder.branchName')" prop="branchName" :rules="$rule.notNull">
          <el-input v-model.trim="model.branchName" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{ $t("common.save") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      model: {
        id: 0,
        branchName: "",
      },
    };
  },
  methods: {
    open(id) {
      this.model.id = id;
      this.dialogVisible = true;
      if (this.model.id) {
        this.getData();
      }
    },
    getData() {
      this.$http
        .get("branchAgency/" + this.model.id)
        .then(({ data }) => {
          this.$assign(this.model, data);
        });
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true;
          this.$http.save("branchAgency", this.model)
            .then(() => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success");
              this.$message.success(this.$t("common.tip.saveSuccess"));
            })
            .catch(() => {
              this.submitLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>