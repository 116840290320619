<template>
  <div>
    <div style="margin-bottom: 10px">
      <el-button
        style="padding: 0 20px; height: 32px; border-radius: 12px; background-color: #0747FD; color: #fff;border: none;"
        :loading="submitLoading" @click="handleSubmit">保存</el-button>
    </div>
    <div style="height: 80vh;overflow: auto">
      <table cellspacing="0" style="width: 100%">
        <thead>
          <tr>
            <th>类别</th>
            <th>索引</th>
            <th>序号</th>
            <th>确认项目</th>
            <th>检查内容</th>
            <th>分值 <br/> 总分：{{ allScore }}</th>
            <th>操作</th>
            <th style="border-right: 1px solid #ccc;">类别操作</th>
          </tr>
        </thead>
        <tbody v-for="(data, index) in tableData" :key="index">
          <tr v-for="(item, sonIndex) in data.safeCheckSettingList" :key="sonIndex">
            <td v-if="sonIndex === 0" :rowspan="data.safeCheckSettingList.length"
              style="width: 150px;text-align: center;">
              <span v-if="item.type === 1">安全</span>
              <span v-if="item.type === 2">基本条件</span>
              <span v-if="item.type === 3">维保质量</span>
            </td>
            <td :label="$t('faultTemplate.SerialNo')" width="50" style="text-align: center;">
              {{ sonIndex + 1 }}
            </td>
            <td style="width: 100px">
              <el-input v-model="item.serialNo"></el-input>
            </td>
            <td>
              <el-input v-model="item.project"></el-input>
            </td>
            <td>
              <el-input v-model="item.content"></el-input>
            </td>
            <td style="width: 100px">
              <el-input v-model="item.score" @input="checkInput(item)" @change="update(item)"></el-input>
            </td>
            <td style="width: 200px;padding-left: 10px;">
              <el-button type="text" @click="remove(index, sonIndex)">
                {{ $t("common.remove") }}
              </el-button>
              <el-button v-if="sonIndex === 0" type="text" class="el-icon-minus" style="margin-left: 20px"></el-button>
              <el-button v-if="sonIndex !== 0" type="text" class="el-icon-caret-top" style="margin-left: 20px"
                @click="handleUp(index, sonIndex)"></el-button>
              <el-button v-if="sonIndex === data.safeCheckSettingList.length - 1" type="text" class="el-icon-minus"
                style="margin-left: 20px"></el-button>
              <el-button v-if="sonIndex !== data.safeCheckSettingList.length - 1" type="text"
                class="el-icon-caret-bottom" style="margin-left: 20px" @click="handDown(index, sonIndex)"></el-button>
            </td>
            <td v-if="sonIndex === 0" style="width: 100px;border-right: 1px solid #ccc;text-align: center;"
              :rowspan="data.safeCheckSettingList.length">
              <el-button type="text" @click="add(index)">
                {{ $t("common.add") }}
              </el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      tableData: [
        {
          safeCheckSettingList: [{
            id: "",
            type: "",
            serialNo: "",
            content: "",
            project: "",
            score: "",
          }],
        },
      ],
    };
  },
  computed: {
    allScore: function () {
      let sum = 0;
      this.tableData.forEach(data => {
        data.safeCheckSettingList.forEach(list => {
          if (parseInt(list.score)) {
            sum += parseInt(list.score);
          }
        });
      });
      return sum;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.safeCheckList = [];
      this.$http.get("safeCheckSetting").then(res => {
        this.tableData = JSON.parse(JSON.stringify(res.data));
      }).catch((error) => {

      });
    },
    checkInput(row) {
      let regex = /^[0-9/]*$/;
      if (!regex.test(row.score) || parseInt(row.score) === 0) {
        this.$message.error("请输入大于0的整数或者/");
        row.score = "";
      }
    },
    update(row) {
      if (row.score === "/") {
        row.project = "★" + row.project;
      }
      if (row.score !== "/") {
        row.project = row.project.replace("★", "");
      }
    },
    handleSubmit() {
      this.submitLoading = true;
      this.$axios.put("safeCheckSetting", this.tableData).then(() => {
        this.$message.success("保存成功");
        this.submitLoading = false;
      }).catch((error) => {
        if (error.response) {
          this.submitLoading = false;
          this.$message.error("保存失败, " + error.response.data.message);
        }
      });
    },
    add(index) {
      let obj = {
        id: "",
        type: "",
        serialNo: "",
        content: "",
        project: "",
        score: "",
        index: this.tableData[index].safeCheckSettingList.length,
      };
      console.log(obj);
      this.tableData[index].safeCheckSettingList.push(obj);
    },
    remove(index, sonIndex) {
      this.$confirm("确定删除吗?", { type: "warning" }).then(() => {
        this.tableData[index].safeCheckSettingList.splice(sonIndex, 1);
        this.$message.success(this.$t("common.tip.deleteSuccess"));
      });
    },
    handleUp(index, sonIndex) {
      let temp = this.tableData[index].safeCheckSettingList[sonIndex];

      this.tableData[index].safeCheckSettingList.splice(sonIndex, 1);
      this.tableData[index].safeCheckSettingList.splice(sonIndex - 1, 0, temp);
    },
    handDown(index, sonIndex) {
      let temp = this.tableData[index].safeCheckSettingList[sonIndex];
      let tempDown = this.tableData[index].safeCheckSettingList[sonIndex + 1];

      this.tableData[index].safeCheckSettingList.splice(sonIndex, 1);
      this.tableData[index].safeCheckSettingList.splice(sonIndex + 1, 0, temp);
    },
    parentHandleUp(index) {
      let temp = this.tableData[index];

      this.tableData.splice(index, 1);
      this.tableData.splice(index - 1, 0, temp);
    },
    parentHandleDown(index) {
      let temp = this.tableData[index];

      this.tableData.splice(index, 1);
      this.tableData.splice(index + 1, 0, temp);
    },
    parentRemove(index) {
      this.tableData.splice(index, 1);
    },
  },
};
</script>

<style scoped>
table {
  border-bottom: 1px solid #ccc;
}

td,
th {
  border: 1px solid #ccc;
  border-right: none;
  border-bottom: none;
  padding: 3px;
}
</style>
