<template>
  <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" width="800px" top="2vh"
    @close="onDialogClose">
    <div v-loading="contentLoading">
      <el-form ref="formValidate" :label-width="$l('190px', '100px')" :model="maintItem" :rules="ruleValidate">
        <el-form-item prop="itemName" :label="$t('maintWorkOrder.maintenanceProjectName')">
          <el-input v-model.trim="maintItem.itemName" :placeholder="$t('common.pleaseEnter')" maxlength="100">
          </el-input>
        </el-form-item>
        <el-form-item prop="maintContent" :label="$t('maintWorkOrder.maintContent')">
          <el-input v-model.trim="maintItem.maintContent" type="textarea" :rows="2"
            :placeholder="$t('common.pleaseEnter')" maxlength="255">
          </el-input>
        </el-form-item>
        <el-form-item prop="maintRequire" :label="$t('maintWorkOrder.maintRequire')">
          <el-input v-model.trim="maintItem.maintRequire" type="textarea" :rows="2"
            :placeholder="$t('common.pleaseEnter')" maxlength="255">
          </el-input>
        </el-form-item>
        <el-form-item prop="maintArea" :label="$t('maintWorkOrder.maintArea')">
          <el-select style="width: 100%;" v-model="maintItem.maintArea" :placeholder="$t('common.pleaseSelect')"
            clearable>
            <el-option v-for="item in maintAreaOption" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="remark" :label="$t('common.remark')">
          <el-input v-model.trim="maintItem.remark" type="textarea" :rows="1" :placeholder="$t('common.pleaseEnter')"
            maxlength="255">
          </el-input>
        </el-form-item>
        <el-form-item v-if="currentUserType === 0" prop="isDefault" :label="$t('common.isDefault')">
          <el-switch v-model="maintItem.isDefault" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">{{
        $t("common.save") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>

import auth from "@/util/auth";

const moduleName = "maintItem";
export default {
  components: {},
  data() {
    return {
      contentLoading: false,
      submitLoading: false,
      saveDisabled: false,
      dialogVisible: false,
      currentUserType: auth.getUserType(),
      maintAreaOption: [
        { value: "机房", label: this.$t("maintWorkOrder.maintenanceArea.machineRoom") },
        { value: "轿厢", label: this.$t("maintWorkOrder.maintenanceArea.bridge") },
        { value: "层站", label: this.$t("maintWorkOrder.maintenanceArea.layer") },
        { value: "井道及底坑", label: this.$t("maintWorkOrder.maintenanceArea.wellRoadAndTheBottomPit") },
        { value: "扶梯", label: this.$t("maintWorkOrder.maintenanceArea.escalator") },
        { value: "其他", label: this.$t("maintWorkOrder.maintenanceArea.other") },
      ],
      maintItem: {
        id: 0,
        itemName: "",
        maintContent: "",
        maintRequire: "",
        maintArea: "",
        remark: "",
        isDefault: 0,
      },
      ruleValidate: {
        itemName: [
          { required: true, message: this.$t("maintWorkOrder.tip.itemName"), trigger: "blur" },
        ],
        maintContent: [
          { required: true, message: this.$t("maintWorkOrder.tip.maintContent"), trigger: "blur" },
        ],
        maintRequire: [
          { required: true, message: this.$t("maintWorkOrder.tip.maintRequire"), trigger: "blur" },
        ],
        maintArea: [
          { required: true, message: this.$t("maintWorkOrder.tip.maintArea"), trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    title() {
      return (this.maintItem.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " +
        this.$t("maintWorkOrder.maintItem");
    },
  },
  methods: {
    open(id) {
      this.maintItem.id = id;
      this.dialogVisible = true;
      if (id > 0) {
        this.getData();
      }
    },
    getData() {
      this.contentLoading = true;
      this.saveDisabled = true;
      this.$api.getById(moduleName, this.maintItem.id).then(res => {
        this.maintItem = res.data;
        this.contentLoading = false;
        this.saveDisabled = false;
      }).catch((error) => {
        this.contentLoading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },
    onDialogClose() {
      this.saveDisabled = false;
      this.$refs.formValidate.resetFields();
      this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
    },
    handleSubmit() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          if (this.maintItem.id === 0) {
            this.maintItem.id = null;
          }
          this.submitLoading = true;
          this.$api.save(moduleName, this.maintItem).then(() => {
            this.submitLoading = false;
            this.dialogVisible = false;
            this.$emit("save-success");
            this.$message.success(this.$t("common.tip.saveSuccess"));
          }).catch(error => {
            this.submitLoading = false;
            this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
          });
        } else {
          this.$message.error(this.$t("common.tip.submitError") + "!");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
